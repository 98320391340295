<template>
  <div class="flex w-full items-center justify-between py-3">
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Previous</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-400">
          Showing
          {{ " " }}
          <span class="font-medium">{{ currentPageStartingNumber }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ currentPageEndingNumber }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ totalResults }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav class="isolate inline-flex -space-x-px rounded-md gap-1" aria-label="Pagination">
          <a
            href="#"
            class="relative inline-flex items-center rounded-l-md px-2 py-2 hover:text-gray-800 text-gray-400 focus:outline-offset-0"
            @click.prevent="prevPage"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
          <a
            v-for="page of firstPages"
            :key="page"
            href="#"
            aria-current="page"
            :class="[
              'firstPage',
              page === currentPage
                ? 'relative z-10 inline-flex items-center border rounded  px-4 py-2 text-sm font-semibold '
                : 'relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-500 rounded  hover:bg-gray-100 md:inline-flex',
            ]"
            @click.prevent="goToPage(page)"
            >{{ page }}</a
          >
          <span
            v-if="currentPage >= 4 && totalPages > 4"
            class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-offset-0"
            >...</span
          >
          <a
            v-for="page of middlePages"
            :key="page"
            href="#"
            aria-current="page"
            :class="[
              page === currentPage
                ? 'relative z-10 inline-flex items-center rounded border-brandDarkBlue-500 text-brandDarkBlue-500 bg-brandDarkBlue-50 bg-opacity-50  px-4 py-2 text-sm font-semibold '
                : 'relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-500  rounded hover:bg-gray-100 focus:z-20 focus:outline-offset-0 md:inline-flex',
            ]"
            @click.prevent="goToPage(page)"
            >{{ page }}</a
          >

          <span
            v-if="totalPages > 6 && currentPage < totalPages - 2"
            class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-offset-0"
            >...</span
          >
          <a
            v-for="page of lastPages"
            :key="page"
            href="#"
            aria-current="page"
            :class="[
              page === currentPage
                ? 'relative z-10 inline-flex items-center rounded border-brandDarkBlue-500 text-brandDarkBlue-500 bg-brandDarkBlue-50 bg-opacity-50  px-4 py-2 text-sm font-semibold'
                : 'relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-500  bg-white hover:bg-gray-100 md:inline-flex',
            ]"
            @click.prevent="goToPage(page)"
            >{{ page }}</a
          >
          <a
            href="#"
            class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 hover:text-gray-800 focus:outline-offset-0"
            @click.prevent="nextPage"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  currentPage: { type: Number, default: 1 },
  totalResults: { type: Number, default: 0 },
  pageSize: {
    type: Number,
    default: 10,
  },
  totalPages: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(["changePage"]);

// Showing [X] of y
const currentPageStartingNumber = computed(() => {
  return props.pageSize * (props.currentPage - 1) + 1;
});

// Showing x of [Y]
const currentPageEndingNumber = computed(() => {
  const end = props.pageSize * props.currentPage;
  return end > props.totalResults ? props.totalResults : end;
});

const firstPages = computed(() => {
  // If the current page is more than 5, show the first page
  if (props.currentPage >= 3 && props.totalPages > 4) {
    return [1];
  }

  return [];
});

const middlePages = computed(() => {
  // If there are no more than three pages, then we just return the total number of pages
  if (props.totalPages <= 3) {
    return Array.from({ length: props.totalPages }, (_, i) => i + 1);
  }

  // If there are more than 3 pages but less than 7, return the first 3
  if (props.totalPages > 3 && props.totalPages <= 7) {
    return [1, 2, 3];
  }

  // Set up the variables
  const currentPage = props.currentPage;
  const pagesToShow = [];

  // Because we're also showing the last page in another function, we can only show
  // up to totalPages - 1 pages on the left
  const maxPagesToShowOnLeft = props.totalPages - 1;

  // Calculate the range of pages to show in the middlePages array
  let startPage;

  if (currentPage === props.totalPages) {
    startPage = Math.max(1, currentPage - 3);
  } else if (currentPage === maxPagesToShowOnLeft) {
    startPage = currentPage - 2;
  } else if (currentPage === 1) {
    startPage = currentPage;
  } else {
    startPage = Math.min(currentPage - 1, maxPagesToShowOnLeft);
  }

  for (let i = startPage; i < startPage + 3 && i <= maxPagesToShowOnLeft; i++) {
    pagesToShow.push(i);
  }

  return pagesToShow;
});

// This computed property calculates the pages to display at the end of the pagination component.
const lastPages = computed(() => {
  // If there are less than or equal to 3 total pages, no pages should be displayed.
  if (props.totalPages <= 3) {
    return;
  }

  // Otherwise show the last page
  return [props.totalPages];
});

// Click to change page
function goToPage(page) {
  emit("changePage", page);
}

function prevPage() {
  if (props.currentPage > 1) {
    goToPage(props.currentPage - 1);
  }
}

function nextPage() {
  if (props.currentPage < props.totalPages) {
    goToPage(props.currentPage + 1);
  }
}

const items = [
  {
    id: 1,
    title: "Back End Developer",
    department: "Engineering",
    type: "Full-time",
    location: "Remote",
  },
  {
    id: 2,
    title: "Front End Developer",
    department: "Engineering",
    type: "Full-time",
    location: "Remote",
  },
  {
    id: 3,
    title: "User Interface Designer",
    department: "Design",
    type: "Full-time",
    location: "Remote",
  },
];
</script>
