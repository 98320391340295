<template>
  <div>
    <PageHeader
      title="Tactics Database"
      sub-title="A curated database of growth tactics to help you scale your business. Search for ideas and add them to your growth plan."
    />

    <gm-page-container>
      <!-- Search and filter -->
      <div class="mb-6 flex place-content-between items-center text-gray-700 w-full">
        <TacticsSearchBar
          @changeGoal="changeGoal"
          @changeChannel="changeChannel"
          @submitSearch="submitSearch"
        />
      </div>
    </gm-page-container>
    <gm-page-container>
      <!-- Tactics grid -->
      <gm-grid v-if="tacticsLoading">
        <gm-card loading v-for="i in 9" :key="i" class="h-52" />
      </gm-grid>
      <gm-grid v-if="!tacticsLoading && tactics.length === 0">
        <gm-card class="h-52 w-full col-span-3">
          <div class="flex justify-center items-center flex-col h-52">
            <MagnifyingGlassIcon class="h-10 w-10 text-gray-400 mb-3" aria-hidden="true" />
            <h2 class="text-xl font-semibold mb-1 text-gray-400">No tactics found</h2>
            <p class="text-gray-400">Try changing your filters or search terms.</p>
          </div>
        </gm-card>
      </gm-grid>
      <gm-grid v-if="!tacticsLoading && tactics.length">
        <gm-card
          v-for="tactic in tactics"
          :key="tactic._id"
          class="h-52"
          :title="tactic.name"
          :description="tactic.description"
          :image="tactic.image"
          :tags="tactic.goal.split(',').map((goal) => goal.trim())"
          @click="openTactic(tactic)"
        >
          <div class="px-4 py-5 sm:p-6">
            <!-- Content goes here -->
            <a
              href="#"
              @click.prevent="openTactic(tactic)"
              class="text-md font-semibold mb-2 hover:text-brand-500"
              >{{ tactic.name }}</a
            >
            <p class="mt-2 text-sm line-clamp-2 text-gray-400">{{ tactic.description }}</p>
          </div>
          <template #footer>
            <div class="flex w-full px-4 py-4 sm:px-6 align-bottom place-content-between">
              <div class="flex items-center overflow-hidden mr-3">
                <span
                  v-for="(goalItem, index) in tactic.goal.split(',')"
                  :key="index"
                  class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 mr-2"
                >
                  {{ goalItem.trim() }}
                </span>
              </div>

              <button
                type="button"
                class="rounded-full bg-brand-50 p-1.5 text-brand-500 shadow-sm hover:bg-brand-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </template>
        </gm-card>
      </gm-grid>
    </gm-page-container>

    <div class="flex w-full pb-8">
      <gm-page-container>
        <Pagination
          :totalResults="totalResults"
          :totalPages="totalPages"
          :pageSize="pageSize"
          :currentPage="page"
          @changePage="changePage"
        />
      </gm-page-container>
    </div>
  </div>
</template>

<script setup>
import PageHeader from "../components/PageHeader.vue";
import Pagination from "../components/ui/Pagination.vue";
import TacticsSearchBar from "../components/TacticsSearchBar.vue";
import { computed, reactive, ref, onMounted } from "vue";
import { PlusIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
let tactics = reactive([]);
const tacticsLoading = ref(true);

// Pagination stuff
const page = ref(1);
const pageSize = ref(15);
const totalPages = ref(1);
const totalResults = ref(0);

const filters = reactive({
  goal: "",
  channels: "",
  description: "",
});

async function changeGoal(goal) {
  filters.goal = goal;
  await retrieveTactics(true);
}

async function changeChannel(channel) {
  filters.channels = channel;
  await retrieveTactics(true);
}

async function submitSearch(searchTerms) {
  filters.description = searchTerms;
  await retrieveTactics(true);
}

function openTactic(tactic) {
  console.log("opening tactic", tactic);
}

async function changePage(newPage) {
  page.value = newPage;
  await retrieveTactics();
}

async function retrieveTactics(resetPagination = false) {
  tacticsLoading.value = true;
  if (resetPagination) {
    page.value = 1;
  }
  let params = new URLSearchParams(
    Object.entries({ ...filters, page: page.value, pageSize: pageSize.value })
  );
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/api/tactics?${params.toString()}`);
    const data = await response.json();
    tactics = data.data;
    totalPages.value = data.totalPages;
    totalResults.value = data.totalResults;
  } catch (err) {
    console.error("Error fetching tactics:", err);
  }
  tacticsLoading.value = false;
}

onMounted(async () => {
  await retrieveTactics();
});
</script>
