<template>
  <div
    class="flex space-y-3 lg:space-y-0 lg:space-x-2 flex-shrink-0 flex-grow lg:h-10 w-full flex-col lg:flex-row"
  >
    <gm-dropdown
      label="Select a Stage"
      type="select"
      :options="goalOptions"
      showLabelBeforeSelected="Stage"
      @change="goalChanged($event)"
      wide
    />
    <gm-dropdown
      label="Select a Channel"
      type="select"
      :options="channelOptions"
      showLabelBeforeSelected="Channel"
      @change="channelChanged($event)"
      wide
    />
    <div class="relative w-full flex items-center h-10 lg:pl-3">
      <input
        type="email"
        name="email"
        id="email"
        class="block w-full h-full py-1.5 text-gray-900 bg-transparent border-b border-gray-200 focus:border-brandDarkBlue-500 focus:border-2 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
        placeholder="Search for tactics"
        v-model="searchTerms"
        v-on:keyup.enter="tacticSearch"
      />
      <div
        v-if="!haveSearchTerms"
        class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-900 cursor-pointer"
        title="Submit search"
        @click="tacticSearch"
      >
        <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
      </div>
      <div
        v-if="haveSearchTerms"
        class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-900 cursor-pointer"
        title="Clear search"
        @click="clearSearch"
      >
        <XCircleIcon class="h-5 w-5" aria-hidden="true" />
      </div>
    </div>
  </div>
</template>

<script setup>
import DropDown from "../components/ui/DropDown.vue";
import { computed, defineEmits, ref } from "vue";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/20/solid";

const emit = defineEmits(["changeGoal", "changeChannel", "submitSearch"]);

const haveSearchTerms = computed(() => searchTerms.value.length > 0);

const goalOptions = [
  { value: "Acquisition", selected: false },
  { value: "Activation", selected: false },
  { value: "Retention", selected: false },
  { value: "Revenue", selected: false },
  { value: "Referral", selected: false },
];

const channelOptions = [
  { value: "Amazon", selected: false },
  { value: "Apps", selected: false },
  { value: "Blog", selected: false },
  { value: "Email", selected: false },
  { value: "Facebook", selected: false },
  { value: "Google", selected: false },
  { value: "Instagram", selected: false },
  { value: "Landing Page", selected: false },
  { value: "LinkedIn", selected: false },
  { value: "Pinterest", selected: false },
  { value: "Podcast", selected: false },
  { value: "Reddit", selected: false },
  { value: "TikTok", selected: false },
  { value: "Twitter", selected: false },
  { value: "YouTube", selected: false },
];

const searchTerms = ref("");

// When the goal dropdown changes
function goalChanged(goal) {
  emit("changeGoal", goal);
}

// When the channel dropdown changes
function channelChanged(channel) {
  emit("changeChannel", channel);
}

function tacticSearch() {
  emit("submitSearch", searchTerms.value);
}

function clearSearch() {
  searchTerms.value = "";
  tacticSearch();
}
</script>

<style></style>
